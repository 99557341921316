import React from 'react'
import './index.scss'

const PayoutDetails = (props) => {
    return (
        <section className="payout-details">
            <div className="wrap">
                <div className="payout-details-title">Payout Details</div>
                <div className="list">
                    <div className="item">
                        <div className="title">
                            10
                            <span>TON</span>
                        </div>
                        <div className="subtitle">Minimum Payout</div>
                    </div>
                    <div className="item">
                        <div className="title">
                            Realtime
                        </div>
                        <div className="subtitle">Reward Estimating</div>
                    </div>
                    <div className="item">
                        <div className="title">We pay</div>
                        <div className="subtitle">Transaction Fee</div>
                    </div>
                    <div className="item">
                        <div className="title">
                            0<span>%</span>
                        </div>
                        <div className="subtitle">Pool Fee</div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default PayoutDetails
