import React from 'react'
import ReactLoading from 'react-loading'
import ton from '../../assets/img/ton.svg'
import './index.scss'

const Statistics = ({ stat }) => {
    return (
        <section className="statistics">
            <div className="wrap">
                <img className="logo" src={ton} alt="" />
                <div className="list">
                    <div className="item">
                        <div className="title">Pool Hashrate</div>
                        {stat ? (
                            <span className="speed">
                                {stat['sha256']?.hashrate?.value ?? '-'}{' '}
                                {stat['sha256']?.hashrate?.dimension}
                            </span>
                        ) : (
                            <ReactLoading
                                type={'cylon'}
                                color="#fff"
                                height={41}
                                delay={300}
                            />
                        )}

                        <div className="name">SHA-256</div>
                        <div className="box">
                            <div className="title">Active Miners</div>
                            <span>
                                {stat ? (
                                    stat['sha256']?.active_miners?.value ?? '-'
                                ) : (
                                    <ReactLoading
                                        type={'cylon'}
                                        color="#fff"
                                        height={41}
                                        delay={300}
                                    />
                                )}
                            </span>
                        </div>
                    </div>

                    <div className="item">
                        <div className="title">Pool Hashrate</div>
                        <span className="speed">
                            {stat ? (
                                <span>
                                    {stat['ethash']?.hashrate?.value ?? '-'}{' '}
                                    {stat['ethash']?.hashrate?.dimension}
                                </span>
                            ) : (
                                <ReactLoading
                                    type={'cylon'}
                                    color="#fff"
                                    height={41}
                                    delay={300}
                                />
                            )}
                        </span>
                        <div className="name">Ethash</div>
                        <div className="box">
                            <div className="title">Active Miners</div>
                            <span>
                                {stat ? (
                                    stat['ethash']?.active_miners?.value ?? '-'
                                ) : (
                                    <ReactLoading
                                        type={'cylon'}
                                        color="#fff"
                                        height={41}
                                        delay={300}
                                    />
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Statistics
