import React from 'react'
import './index.scss'
import ton from '../../assets/img/ton.svg'
import tg from '../../assets/img/tg.svg'

const Footer = (props) => {
    return (
        <footer className="footer" id="footer">
            <div className="wrap">
                <img className="logo" src={ton} alt="" />
                <div className="links">
                    <a
                        href="https://t.me/infiniteTON_bot"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={tg} alt="" />
                        <div className="box">
                            <span>bot:</span> @infiniteTON_bot
                        </div>
                    </a>
                    <a
                        href="https://t.me/whalespool"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={tg} alt="" />
                        <div className="box">
                            <span>chat (Eng):</span> @whalespool
                        </div>
                    </a>
                    <a
                        href="https://t.me/tonwhaleschat"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={tg} alt="" />
                        <div className="box">
                            <span>chat (Any lang):</span> @tonwhaleschat
                        </div>
                    </a>
                </div>
            </div>
        </footer>
    )
}
export default Footer
