import React from 'react'
import Faqitem from '../faqItem/index'
import './index.scss'

const Faq = () => {
    return (
        <section className="faq" id="faq">
            <div className="wrap">
                <div className="faq-title">FAQ</div>
                <Faqitem
                    header="How to start mining TON?"
                    body={
                        <span>1) Sign up in the Telegram bot @infiniteTON_bot;
                            <br/>2) Configure your equipment according to the instructions in the bot;
                            <br/><br/>Bot functionality:
                            <br/>- Track mining statistics;
                            <br/>- Request payout;
                            <br/>- Payout history;
                        </span>
                    }
                />
                <Faqitem
                    header="Will TON mining continue after the end of the official TON mining?"
                    body="Yes! You will be able to mine TON on GPU (Ethash algorithm as for Ethereum) and on ASIC SHA-256"
                />
                <Faqitem
                    header="Which GPUs and miners are supported?"
                    body={
                        <span>All GPU models that are suitable for Ethereum mining are supported: RTX 3090, RTX 3080, GTX 1070, etc.
                            <br/>Lolminer, Bminer, T-Rex Miner, PhoenixMiner, GMiner, NBMiner, TeamRedMiner are supported.
                            <br/>
                            <div className="accordion-body-code">Lolminer:<br/>lolMiner.exe --algo ETHASH --pool stratum+tcp://ethash.infinityton.com:4444 --user username.worker1 --watchdog exit --ethstratum=ETHV1</div>
                            <div className="accordion-body-code">Bminer:<br/>bminer.exe -uri ethstratum://username.worker1@ethash.infinityton.com:4444</div>
                            <div className="accordion-body-code">Gminer:<br/>miner.exe --proto stratum --algo ethash --server ethash.infinityton.com:4444 --user username.worker1 --devices 0</div>
                            <div className="accordion-body-code">PhoenixMiner:<br/>PhoenixMiner.exe -pool ethash.infinityton.com:4444 -wal username.worker1 -proto 4</div>
                            <div className="accordion-body-code">T-rex miner:<br/>t-rex.exe -a ethash -o stratum2+tcp://ethash.infinityton.com:4444 -u username.worker1</div>
                            <div className="accordion-body-code">Nbminer:<br/>nbminer.exe -a ethash -o nicehash+tcp://ethash.infinityton.com:4444 -u username.worker1</div>
                        </span>
                    }
                />
                <Faqitem
                    header="Which ASICs are supported?"
                    body={<span>Any SHA-256 ASICs are supported.
                        <br/>For example: Antminer S19 Pro, AvalonMiner 1246, Antminer S9j, Innosilicon T2T.
                    </span>}
                />
            </div>
        </section>
    )
}
export default Faq
