import React from 'react'
import ReactLoading from 'react-loading'
import tg from '../../assets/img/tg.svg'
import machine1 from '../../assets/img/machine1.svg'
import machine2 from '../../assets/img/machine2.svg'
import diamond from '../../assets/img/diamond.svg'
import './index.scss'

const Machines = ({ calc }) => {
    const calculations = (reward, speed) => {
        const rewardByMachine = reward * speed
        return rewardByMachine.toFixed() > 0
            ? rewardByMachine.toFixed(1)
            : rewardByMachine.toFixed(2)
    }

    return (
        <section className="machines">
            <div className="wrap">
                <div className="sponsored">
                    <a target="_blank" href="https://tonwhales.com/mining">Sponsored by tonwhales.com</a>
                </div>
            </div>
            <div className="wrap">
                <div className="card">
                    <div className="card-header">
                        <div className="box">
                            <span>TON</span>
                            <p>mining on</p>
                        </div>
                        <div className="box">
                            <span>ASIC </span>
                            <p className="bold">SHA-256</p>
                        </div>
                        <img src={machine1} alt="" />
                    </div>

                    <div className="daily-earning">
                        <div className="box">
                            <div className="title">Expected daily earnings</div>
                            <span>per 1 TH/s</span>
                        </div>
                        <div className="price">
                            {calc ? (
                                <>
                                    <img src={diamond} alt="" />
                                    <p>
                                        {calculations(calc.reward['sha256'] ?? 0, 1)}{' '}
                                        <span>/day</span>
                                    </p>
                                </>
                            ) : (
                                <ReactLoading
                                    type="cylon"
                                    color="#fff"
                                    height={58}
                                    delay={300}
                                />
                            )}
                        </div>
                    </div>

                    <div className="popular-hardware">
                        <div className="title">Popular hardware</div>
                        <div className="row">
                            <div className="box">
                                Antminer S19 Pro
                                <span>110 TH/s</span>
                            </div>
                            <div className="box">
                                {calc ? (
                                    <>
                                        {calculations(
                                            calc.reward['sha256'],
                                            110
                                        )}{' '}
                                        TON
                                        {''}
                                        <span>/day</span>
                                    </>
                                ) : (
                                    <ReactLoading
                                        type="cylon"
                                        color="#fff"
                                        height={24}
                                        delay={300}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                AvalonMiner 1246
                                <span>90 TH/s</span>
                            </div>
                            <div className="box">
                                {calc ? (
                                    <>
                                        {calculations(
                                            calc.reward['sha256'],
                                            90
                                        )}{' '}
                                        TON
                                        {''}
                                        <span>/day</span>
                                    </>
                                ) : (
                                    <ReactLoading
                                        type="cylon"
                                        color="#fff"
                                        height={24}
                                        delay={300}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                Antminer S9j
                                <span>14 TH/s</span>
                            </div>
                            <div className="box">
                                {calc ? (
                                    <>
                                        {calculations(
                                            calc.reward['sha256'],
                                            14
                                        )}{' '}
                                        TON
                                        {''}
                                        <span>/day</span>
                                    </>
                                ) : (
                                    <ReactLoading
                                        type="cylon"
                                        color="#fff"
                                        height={24}
                                        delay={300}
                                    />
                                )}
                            </div>
                        </div>{' '}
                    </div>

                    <div className="mining">
                        <div className="title">Mining</div>
                        <div className="address single selectall">
                            stratum+tcp://sha256.infinityton.com:3333
                        </div>
                        <div className="row">
                            <span>user =</span>
                            <div className="address selectall">{`<username>.<worker name>`}</div>
                        </div>
                        {/*<div className="row">
                            <span className="grey">or</span>
                            <div className="address">
                                {'<username>.<worker name>'}
                            </div>
                        </div>*/}
                    </div>

                    <a
                        href="https://tonwhales.com/mining"
                        target="_blank"
                    >Start ASIC mining</a>
                </div>

                <div className="card">
                    <div className="card-header">
                        <div className="box">
                            <span>TON</span>
                            <p>mining on</p>
                        </div>
                        <div className="box">
                            <span>GPU </span>
                            <p className="bold">Ethash</p>
                        </div>
                        <img src={machine2} alt="" />
                    </div>

                    <div className="daily-earning">
                        <div className="box">
                            <div className="title">Expected daily earnings</div>
                            <span>per 100 MH/s</span>
                        </div>
                        <div className="price">
                            {calc ? (
                                <>
                                    <img src={diamond} alt="" />
                                    <p>
                                        {calculations(calc.reward['ethash'] ?? 0, 1)}{' '}
                                        {/*{Math.round((calc.reward['ethash'] ?? 0) * 1000) / 1000}{' '}*/}
                                        <span>/day</span>
                                    </p>
                                </>
                            ) : (
                                <ReactLoading
                                    type="cylon"
                                    color="#fff"
                                    height={58}
                                    delay={300}
                                />
                            )}
                        </div>
                    </div>

                    <div className="popular-hardware">
                        <div className="title">Popular hardware</div>
                        <div className="row">
                            <div className="box">
                                RTX 3090
                                <span>114 MH/s</span>
                            </div>
                            <div className="box">
                                {calc ? (
                                    <>
                                        {calculations(
                                            calc.reward['ethash'] / 100.0,
                                            114
                                        )}{' '}
                                        TON
                                        {''}
                                        <span>/day</span>
                                    </>
                                ) : (
                                    <ReactLoading
                                        type="cylon"
                                        color="#fff"
                                        height={24}
                                        delay={300}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                RTX 3080
                                <span>91.5 MH/s</span>
                            </div>
                            <div className="box">
                                {calc ? (
                                    <>
                                        {calculations(
                                            calc.reward['ethash'] / 100.0,
                                            91.5
                                        )}{' '}
                                        TON
                                        {''}
                                        <span>/day</span>
                                    </>
                                ) : (
                                    <ReactLoading
                                        type="cylon"
                                        color="#fff"
                                        height={24}
                                        delay={300}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                GTX 1070
                                <span>23.5 MH/s</span>
                            </div>
                            <div className="box">
                                {calc ? (
                                    <>
                                        {calculations(
                                            calc.reward['ethash'] / 100.0,
                                            23.5
                                        )}{' '}
                                        TON
                                        {''}
                                        <span>/day</span>
                                    </>
                                ) : (
                                    <ReactLoading
                                        type="cylon"
                                        color="#fff"
                                        height={24}
                                        delay={300}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="mining">
                        <div className="title">Mining</div>
                        <div className="address single selectall">
                            ethash.infinityton.com:4444
                        </div>
                        <div className="row">
                            <span>wallet =</span>
                            <div className="address selectall">{`<username>.<worker name>`}</div>
                        </div>
                        {/*<div className="row">
                            <span className="grey">or</span>
                            <div className="address">
                                {'<username>.<worker name>'}
                            </div>
                        </div>*/}
                    </div>

                    <a
                        href="https://tonwhales.com/mining"
                        target="_blank"
                    >Start GPU mining</a>
                </div>
            </div>
        </section>
    )
}
export default Machines
