import React, { useState } from 'react'
import clsx from 'clsx'
import './index.scss'

const FaqItem = ({ header, body }) => {
    const [isOpen, setOpen] = useState(false)

    const accordionHandler = () => {
        setOpen((prev) => !prev)
    }

    return (
        <div className={clsx('accordion', isOpen && 'opened')}>
            <button className="accordion-header" onClick={accordionHandler}>
                {header}
            </button>
            <div className="accordion-body">{body}</div>
        </div>
    )
}
export default FaqItem
