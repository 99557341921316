import React from 'react'
import './index.scss'
import diamond from '../../assets/img/diamond.svg'

const Header = (props) => {
    const scrollToFaq = (e, block) => {
        e.preventDefault()
        const section = document.querySelector(block)
        section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
    return (
        <header className="header">
            <div className="wrap">
                <img src={diamond} alt="" />
                <nav className="nav">
                    <a href="/" onClick={(e) => scrollToFaq(e, '#faq')}>
                        FAQ
                    </a>
                    {/*<a href="/" onClick={(e) => scrollToFaq(e, '#footer')}>*/}
                    {/*    Community chat*/}
                    {/*</a>*/}
                    <a
                        href="https://t.me/whalespool"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Community chat
                    </a>
                    <a
                        href="https://t.me/infiniteTON_bot"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Telegram bot
                    </a>
                </nav>
            </div>
        </header>
    )
}
export default Header
