import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Header from './components/header'
import Statistics from './components/statistics'
import Machines from './components/machines'
import PayoutDetails from './components/payoutDetails'
import Faq from './components/faq'
import Footer from './components/footer'

function App() {
    const [data, setData] = useState({})

    const loadData = () => {
        axios
            .get(`/api/pool/stat`)
            .then((res) => {
                setData(res.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        loadData()
        const intervalCall = setInterval(() => {
            loadData()
        }, 30000)
        return () => {
            clearInterval(intervalCall)
        }
    }, [])

    return (
        <div className="App app">
            <div className="main">
                <Header />

                <Statistics stat={data?.pool?.stat} />
                <Machines calc={data?.pool?.calc} />

                <PayoutDetails />
                <Faq />
                <Footer />
            </div>
        </div>
    )
}

export default App
